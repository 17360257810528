
export const breakpoints = {
    zero: 0,
    xxs: 390,
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400
};

const unit = 'px';

const mapCssBreakPoints = () => {
    const keys = Object.keys(breakpoints);
    const result = {};
    keys.forEach(key => result[key] = `${breakpoints[key]}${unit}`);
    return result;
}

export const cssBreakpoints = mapCssBreakPoints();
