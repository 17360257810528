/* eslint-disable no-return-assign */
const TRANSLATIONS_KEY = 'TRANSLATIONS';
const GLOBAL_KEY = 'translations';

const windowProvider = {
  get: () => window[TRANSLATIONS_KEY],
  set: (translations) => window[TRANSLATIONS_KEY] = translations,
  append: (translations) => {
    if (!window[TRANSLATIONS_KEY]) window[TRANSLATIONS_KEY] = {};
    window[TRANSLATIONS_KEY] = { ...window[TRANSLATIONS_KEY], ...translations };
  }
};

let provider = windowProvider;

export const setTranslationProvider = fn => provider = fn;
export const register = (translations) => provider.append(translations);

const keyFormat = key => `%{${key}}`;

const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, 'g'), replace);
};

export const t = (key, params = {}) => {
  const translations = provider.get() || {};

  if (translations[key] === undefined || translations[key] === null) {
    return key;
  }

  let result = translations[key];

  Object.keys(params).forEach(key => {
    result = replaceAll(result, keyFormat(key), params[key]);
  });

  return result;
};

window[GLOBAL_KEY] = {
  register
};
