import { SvgIcon } from '@mui/material';
import React from 'react';

const Check = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 10">
      <path d="M11.5917 1.00834C11.5142 0.930232 11.4221 0.868237 11.3205 0.82593C11.219 0.783622 11.11 0.761841 11 0.761841C10.89 0.761841 10.7811 0.783622 10.6796 0.82593C10.578 0.868237 10.4858 0.930232 10.4084 1.00834L4.20004 7.22501L1.59171 4.60834C1.51127 4.53064 1.41632 4.46955 1.31227 4.42854C1.20823 4.38754 1.09713 4.36743 0.985308 4.36937C0.873491 4.3713 0.76315 4.39524 0.660584 4.43982C0.558019 4.4844 0.465238 4.54874 0.387539 4.62917C0.309841 4.70961 0.248746 4.80456 0.207742 4.9086C0.166739 5.01265 0.14663 5.12375 0.148565 5.23557C0.150499 5.34739 0.174439 5.45773 0.219017 5.56029C0.263595 5.66286 0.327938 5.75564 0.408373 5.83334L3.60837 9.03334C3.68584 9.11145 3.77801 9.17344 3.87956 9.21575C3.98111 9.25806 4.09003 9.27984 4.20004 9.27984C4.31005 9.27984 4.41897 9.25806 4.52052 9.21575C4.62207 9.17344 4.71424 9.11145 4.79171 9.03334L11.5917 2.23334C11.6763 2.1553 11.7438 2.06059 11.79 1.95518C11.8361 1.84976 11.86 1.73592 11.86 1.62084C11.86 1.50575 11.8361 1.39192 11.79 1.2865C11.7438 1.18108 11.6763 1.08637 11.5917 1.00834Z" />
    </SvgIcon>
  );
};

export default Check;
